import {getImage, login} from '@/api/api'
import {getToken, removeToken, setToken} from '@/utils/auth'

const user = {
    state: {
        token: getToken(),
        img: '',
        qrcode: '',
        enterpriseId: '',
        enterpriseName: '',
    },

    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token
        },
        SET_IMG: (state, img) => {
            state.img = img
        },
        SET_QRCODE: (state, qrcode) => {
            state.qrcode = qrcode
        },
        SET_ENTERPRISE_ID: (state, id) => {
            state.enterpriseId = id
        },
        SET_ENTERPRISE_NAME: (state, name) => {
            state.enterpriseName = name
        },
    },

    actions: {
        // 登录
        Login({commit}, userInfo) {
            const username = userInfo.username.trim()
            const code = userInfo.code
            return new Promise((resolve, reject) => {
                login(username, code).then(res => {
                    setToken(res.token)
                    commit('SET_TOKEN', res.token)
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        },
        // 查询二维码
        getImgInfo({commit}, type) {
            return new Promise((resolve, reject) => {
                getImage(type).then(res => {
                    commit('SET_IMG', "https://zl-process.oss-cn-hangzhou.aliyuncs.com/" + res.data.enterpriseLogo)
                    window.localStorage.setItem("img","https://zl-process.oss-cn-hangzhou.aliyuncs.com/" + res.data.enterpriseLogo)
                    if (res.data.applicationUrl) {
                        commit('SET_QRCODE', "https://zl-process.oss-cn-hangzhou.aliyuncs.com/" + res.data.bankAppUrl)
                        window.localStorage.setItem("qrcode", "https://zl-process.oss-cn-hangzhou.aliyuncs.com/" + res.data.bankAppUrl)
                    }
                    // 新增：提交 enterpriseId
                    commit('SET_ENTERPRISE_ID', res.data.id)
                    commit('SET_ENTERPRISE_NAME', res.data.enterpriseName)
                    window.localStorage.setItem("enterpriseId", res.data.id)
                    window.localStorage.setItem("enterpriseName", res.data.enterpriseName)
                    let url = "https://zl-process.oss-cn-hangzhou.aliyuncs.com/" + res.data.enterpriseLogo;
                    resolve(url)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        // 清空token
        clearToken({commit}) {
            return new Promise((resolve) => {
                commit('SET_TOKEN', null);
                removeToken();
                resolve();
            })
        }
    }
}

export default user
